<template>
  <div>
    <div class="modal_page center d-col" ref="modal_page">
       <FocusLoop>
      <form
        @submit.prevent="post"
        ref="modal_window"
        class="modal_window d-flex d-col space-between"
      >
        <div class="pa-30 darkBg text-center font-24">{{ title }}</div>
        <div>
          <div class="body" v-if="firstSlide">
            <div class="weight-300 text-center font-16 mt-20">
              <div class="pb-20">
                {{ $t("modal.rate.thank") }}
              </div>
              <div>{{ $t("modal.rate.please") }}</div>
              <div>
                {{ $t("modal.rate.always") }}
              </div>
            </div>
          </div>
          <div v-if="!firstSlide" class="body mt-20">
            {{ $t("modal.rate.apprechiate") }}
          </div>
          <div class="lightBg py-20 mt-30 center" v-if="firstSlide">
            <div
              v-for="index in 5"
              :key="index"
              @click="selectRating(index)"
              class="star_wrapper center pointer filter"
            >
              <s-icon :color="ratingMethod(index)" height="36">star</s-icon>
            </div>
          </div>
          <div v-if="!firstSlide" class="d-flex justify-center my-40 body">
            <s-btn
              type="button"
              @click.native="closeModal"
              height="36"
              width="300"
              class="green"
            >
              {{ $t("modal.close") }}</s-btn
            >
          </div>
          <div v-if="firstSlide" class="d-flex justify-center my-40 body">
            <s-btn type="submit" height="36" width="300" class="green">
              {{ $t("modal.rate.submit") }}</s-btn
            >
          </div>
        </div>
      </form>
      </FocusLoop>
    </div>
    <div class="center image_wrapper">
      <a
        target="_blank"
        href="https://apps.apple.com/sk/app/securmeet/id1508898635"
      >
        <img src="/img/static/app-store-badge-logo-hover.png" alt=""
      /></a>
      <a
        target="_blank"
        href="https://play.google.com/store/apps/details?id=me.securcom.meet"
      >
        <img src="/img/static/google-play-badge-hover.svg" alt=""
      /></a>
      <a target="_blank" href="www.securmeet.com">
        <img src="/img/static/desktop-download-hover.png" alt=""
      /></a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      rating: 0,
      firstSlide: true,
    };
  },
  methods: {
    async post() {
      this.firstSlide = false;
      // await this.$store.dispatch("user/experienceRating", this.rating);
      setTimeout(() => {
        this.closeModal();
      }, 1000);
    },
    closeModal() {
      gsap.to(this.$refs.modal_page, {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
      });
      gsap.to(this.$refs.modal_window, {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => this.onComplete(),
      });
    },
    ratingMethod(val) {
      return val > this.rating ? "dark" : "green";
    },
    selectRating(val) {
      this.rating = val;
    },
    onComplete() {
      this.$store.commit("modals/rateExperience", false);
      this.$router.push("/");
    },
  },
  computed: {
    title() {
      return this.firstSlide
        ? this.$t("modal.rate.title_rate")
        : this.$t("modal.rate.title_thank");
    },
  },
  mounted() {
    gsap.from(this.$refs.modal_page, {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    gsap.from(this.$refs.modal_window, {
      scale: 0.7,
      duration: 0.3,
      ease: "power2.out",
    });
  },
};
</script>

<style scoped>
.filter {
  background-color: #409875;
}

.image_wrapper img {
  opacity: 0.5;
  padding-left: 5px;
  padding-right: 5px;
}

.image_wrapper img:hover {
  opacity: 1;
}

.modal_page {
  top: 0px;
  width: 100%;
  height: calc(100% - 40px);
  background: rgba(41, 41, 41, 0.6);
  z-index: 0;
}

.modal_window {
  max-width: 650px;
  color: #b7c8df;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
  box-sizing: border-box;
}

.body {
  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;
}

.darkBg {
  background-color: #1d2533;
}

.lightBg {
  background-color: #3f4a5b;
}

.image_wrapper {
  background: rgba(41, 41, 41, 0.6);
}

.star_wrapper {
  background-color: #141925;
  border-radius: 50%;
  padding: 6px;
  padding-bottom: 7px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  margin-left: 10px;
  margin-right: 10px;
}

.star_wrapper:hover {
  background-color: #246449;
}

@media only screen and (max-width: 576px) {
  .body {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .star_wrapper {
    margin-left: 3px;
    margin-right: 3px;
  }
}

@media only screen and (max-width: 445px) {
  .modal_page {
    height: calc(100% - 48px);
  }
}
@media only screen and (max-width: 286px) {
  .modal_page {
    height: calc(100% - 92px);
  }
}
</style>